.textarea {
  position: relative;
  color: $gray;
  textarea {
    width: 100%;
    color: inherit;
    font-size: $fz-sm;
    border: 1px solid  $gray-light;
    // min-height: 45px
    height: 45px;
    padding: 10px 12px;
    resize: none;
    overflow: hidden;
    @include tr(border-color .3s);
    &:focus {
      border-color: $red-accent; } }

  &--lg {
    textarea {
      border: 0;
      border-bottom: 1px solid  $gray;
      padding: 5px 0;
      text-transform: uppercase;
      height: 35px; }
    &--placeholder {}
    padding-top: 15px;
    label {
      position: absolute;
      font-size: $fz-md;
      text-transform: uppercase;
      top: 25px;
      left: 0;
      pointer-events: none;
      @include tr(transform .3s, top .3s, color .3s); }
    textarea {
      &:focus + label {
        // top: 0
        // transform: translate(-.7em, 0) scale(.8)
        transform: translate(0, -25px);
        font-size: .75em;
        color: $red-accent; }
      &.has-text + label {
        // top: 0
        // transform: translate(-.7em, 0) scale(.8)
        transform: translate(0, -25px);
        font-size: .75em; } } } }
