.letters {
  position: absolute;
  width: 100%;
  height: 0;
  top: 56%;
  // transform: translate(0, -50%)
  left: 0;
  &--7 {
    transform: translate(0, 0);
    top: var(--section-padding-lg, 50px);
    .letter {
      @include black;
      @include css-lock(50, 200, 320, 1680, font-size);
      transform: translate(0, 0);
      @include md {
        display: block !important; }
      span {
        transform: translate(-100%, 0); }
      &--1 {
        left: $left-7-1; }
      &--2 {
        left: $left-7-2; }
      &--3 {
        left: $left-7-3; }
      &--4 {
        left: $left-7-4; }
      &--5 {
        left: $left-7-5; }
      &--6 {
        left: $left-7-6; }
      &--7 {
        left: $left-7-7; } } } }

.letter {
  $this: &;
  font-size: 550px;
  @include css-lock(150, 550, 320, 1199, font-size);
  color: rgba($white, .2);
  text-transform: uppercase;
  z-index: 0;
  @include bold;
  line-height: .8;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  overflow: hidden;
  pointer-events: none;
  @include md {
    &:not(.letter--fu) {
      display: none; } }
  span {
    display: inline-block;
    transform: translate(-100%, 0); }
  &--1 {
    left: $left-1; }
  &--2 {
    left: $left-2; }
  &--3 {
    left: $left-3; }

  &--fu {
    &.letter--1 {
      left: $left-1-fucked-up;
      @include sm {
        left: $left-1-fucked-up-mob; } }
    &.letter--2 {
      left: $left-2-fucked-up;
      @include sm {
        left: $left-2-fucked-up-mob; } }
    &.letter--3 {
      left: $left-3-fucked-up;
      @include sm {
        left: $left-3-fucked-up-mob; } } } }
