.form {
  $this: &;
  &--contacts {
    #{$this}__field {
      margin-bottom: 15px;
      @include sm {
        margin-bottom: 5px; } }
    #{$this}__btn {
      text-align: center; } }
  &--subscribe-lg {
    #{$this}__field {
      margin-bottom: 35px;
      @include md {
        margin-bottom: 25px; }
      @include sm {
        margin-bottom: 15px; }
      &:first-child {
        @include flex(flex-start, flex-end);
        @include xs {
          display: block;
          .input {
            padding-right: 0; } }
        .input {
          flex-grow: 1;
          padding-right: 25px; } } } } }

