.controls {
  $this: &;
  @include flex(flex-start, center, row, wrap);
  &__title {
    display: inline-block;
    text-transform: uppercase;
    margin-right: 5px; }
  &__text {
    display: inline-block;
    color: $red;
    text-transform: uppercase; }

  &--col {
    display: block;
    #{$this}__item {
      width: auto;
      margin-bottom: 0 !important;
      padding-left: 0 !important;
      font-size: $fz-sm;
      color: $gray;
      @include notlast {
        border-right: 0; } }
    #{$this}__text-text {
      color: $c-text; } } }


.controls__item {
  width: 33.33333%;
  padding-left: 2%;
  padding-right: 1.5%;
  @include xllopen {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    .select {
      justify-content: flex-start;
      label {
        margin-right: 10px; } }
    @include notlast {
      border: 0;
      margin-bottom: 10px; } }
  @include xl {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    .select {
      justify-content: flex-start;
      label {
        margin-right: 10px; } }
    @include notlast {
      border: 0;
      margin-bottom: 10px; } }
  @include xs {
    @include notlast {
      margin-bottom: 10px; } }
  @include reg;
  @include notlast {
    border-right: 1px solid  $c-border; }
  .select {
    @include flex(space-between, center);
    label {
      @include bold;
      font-size: $fz-md; } }
  &:nth-child(2),
  &:nth-child(3) {
    @include xllopen {
      width: auto;
      margin-bottom: 0; }
    @include xlopen {
      width: auto;
      margin-bottom: 10px; }
    @include xl {
      width: auto;
      margin-bottom: 0; }
    @include xs {
      width: 100%; } } }
