.is-center {
  text-align: center; }

.is-col-6-sm {
  @include smmin {
    max-width: 50%; } }

.is-col-5-sm {
  @include smmin {
    max-width: percentage(5/12); } }

.is-col-4 {
  max-width: percentage(4/12); }

.is-col-3-lg {
  @include lgmin {
    max-width: percentage(3/12); } }

.is-col-6-5 {
  max-width: percentage(6.5/12);
  @include md {
    max-width: none; } }
