.pdf-list {}

.pdf-link-block {
  $this: &;
  padding-top: 75px;
  @include notlast {
    border-bottom: 1px solid $gray-lighten; }
  @include lg {
    padding-top: 50px; }
  @include md {
    padding-top: 40px; }
  @include sm {
    padding-top: 25px; }
  &:last-child {
    padding-bottom: 90px;
    @include lg {
      padding-bottom: 70px; }
    @include md {
      padding-bottom: 50px; }
    @include sm {
      padding-bottom: 30px; }
    #{$this}__block {
      margin-bottom: 0; } }
  &__title {
    margin-bottom: 15px; }
  &__block {
    margin-bottom: 90px;
    @include lg {
      margin-bottom: 70px; }
    @include md {
      margin-bottom: 50px; }
    @include sm {
      margin-bottom: 30px; } }
  &__block-top {
    @include flex();
    margin-bottom: 40px;
    @include sm {
      margin-bottom: 30px; }
    @include xs {
      display: block;
      margin-bottom: 15px; } }
  &__block-title {
    width: 50%;
    padding-right: 10px;
    @include xs {
      width: 100%;
      margin-bottom: 10px; } }
  &__text {
    width: 50%;
    @include xs {
      width: 100%; } }
  &__item {
    @include flex(space-between, center);
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: 1px solid $gray-darken;
    border-bottom: 1px solid $gray-darken;
    @include notfirst {
      border-top: 0; }
    @include sm {
      padding-top: 10px;
      padding-bottom: 10px; } }
  &__meta {
    @include flex(flex-start, center); }
  &__meta-item {
    text-transform: uppercase;
    display: block;
    color: $gray;
    @include notfirst {
      margin-left: 30px;
      @include sm {
        margin-left: 20px; }
      @include xs {
        margin-left: 10px; } }
    .icon {
      fill: $red-accent;
      font-size: 20px; } }
  &__item-title {
    color: $blue;
    text-decoration: underline;
    @include semi;
    padding-right: 10px; } }
