.popup {
  $this: &;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 20;
  background-color: rgba(#000, .85);
  @include vertical;
  visibility: hidden;
  opacity: 0;
  padding-left: 15px;
  padding-right: 15px;
  overflow: auto;
  @include tr(opacity .3s, visibility .3s);
  &.is-active {
    visibility: visible;
    opacity: 1;
    #{$this}__inner {
      transform: translate(0, 0); } }
  &__inner {
    display: inline-block;
    vertical-align: middle;
    font-size: $fz;
    background-color: $white;
    position: relative;
    padding: 75px 45px 85px;
    min-width: 670px;
    transform: translate(0, -100px);
    @include tr(transform .3s);
    @include sm {
      min-width: 0;
      padding: 50px 25px; }
    @include xs {
      padding: 40px 15px 20px; } }
  &__close {
    @include icon-close(15, 1, currentColor);
    position: absolute;
    top: 20px;
    right: 20px;
    @include tr(color .3s);
    @include hover {
      color: $red; } }
  &__title {
    margin-bottom: 20px;
    @include sm {
      margin-bottom: 15px; }
    @include xs {
      margin-bottom: 10px; } }
  &__title-line {
    margin-bottom: 15px;
    font-size: 0;
    @include sm {
      margin-bottom: 10px; }
    @include xs {
      display: none; } }
  &__subttl {
    margin-bottom: 20px;
    @include sm {
      margin-bottom: 10px; } }

  &--cart {
    #{$this}__inner {
      padding: 50px 0px 0px;
      text-align: left; } } }
