@mixin xllopen {
  @include rmix(1200, 1599) {
    .has-aside-open & {
      @content; } } }
@mixin xlopen {
  @include rmix(1200, 1399) {
    .has-aside-open & {
      @content; } } }
@mixin xll {
  @include r(1599) {
    @content; } }
@mixin xl {
  @include r(1399) {
    @content; } }
@mixin lg {
  @include r(1199) {
    @content; } }
@mixin md {
  @include r(991) {
    @content; } }
@mixin sm {
  @include r(767) {
    @content; } }
@mixin xs {
  @include r(575) {
    @content; } }
@mixin xss {
  @include r(479) {
    @content; } }

@mixin lgmin {
  @include rmin(1200) {
    @content; } }
@mixin mdmin {
  @include rmin(992) {
    @content; } }
@mixin smmin {
  @include rmin(768) {
    @content; } }
@mixin xsmin {
  @include rmin(576) {
    @content; } }
