.input {
  position: relative;
  color: $gray;
  input {
    width: 100%;
    color: inherit;
    font-size: $fz-sm;
    border: 1px solid  $gray-light;
    height: 45px;
    padding: 10px 12px;
    background-color: transparent;
    @include tr(border-color .3s);
    &:focus {
      border-color: $red-accent; } }
  &.input--placeholder {
    label {
      top: 32px;
      // top: calc(50% + 10px)
      // transform: translate(0, -50%)
      left: 10px; } }

  &--arrow {
    @include before {
      position: absolute;
      height: 1px;
      width: 15px;
      background-color: currentColor;
      right: 12px;
      top: 40px;
      // top: calc(50% + 10px)
 }      // transform: translate(0, -50%)
    @include after {
      @include icon-arrow(10, 10, 1, currentColor, -135);
      position: absolute;
      right: 12px;
      top: 36px;
      // top: calc(50% + 10px)
      // transform: translate(0, -50%) rotate(-135deg)
      transform: rotate(-135deg); }
    &.input--placeholder {
      padding-top: 20px; } }
  &--md {
    input {
      border: 0;
      border-bottom: 1px solid  $gray;
      font-size: $fz;
      padding: 5px 0;
      height: 35px; }
    &.input--placeholder {
      label {
        left: 0;
        // top: 1em
        top: 26px;
        // top: calc(50% + 10px)
 }        // transform: translate(0, -50%)
      input {
        &:focus + label {
 } } } }          // transform: translate(-.7em, 0) scale(.8)
  &--gray {
    color: $gray-dark;
    input {
      border-color: $gray-dark;
      font-size: $fz;
      padding: 5px 0;
      height: 35px; } }
  &--lg {
    input {
      border: 0;
      border-bottom: 1px solid  $gray;
      padding: 5px 0;
      text-transform: uppercase;
      height: 35px;
      font-size: $fz-md; }
    label {
      font-size: $fz-md;
      text-transform: uppercase; }
    &.input--placeholder {
      label {
        left: 0;
        top: 18px; } } }
  &--placeholder {
    padding-top: 15px;
    label {
      position: absolute;
      // top: 1.2em
      top: 26px;
      // top: calc(50% + 10px)
      // transform: translate(0, -50%)
      left: 0;
      pointer-events: none;
      @include tr(transform .3s, top .3s, color .3s); }
    input {
      &:focus + label {
        transform: translate(0, calc(0px - (50% + 15px)));
        font-size: .75em;
        color: $red-accent; }
      &.has-text + label {
        transform: translate(0, calc(0px - (50% + 15px)));
        font-size: .75em; } } } }
