.error {
  text-align: center;
  position: relative;
  z-index: 1;
  &__oops {
    color: $red-accent;
    @include semi;
    margin-bottom: 30px;
    @include sm {
      margin-bottom: 20px; } }
  &__title {
    margin-bottom: 20px;
    @include sm {
      margin-bottom: 15px; } }
  &__subttl {
    margin-bottom: 20px;
    @include sm {
      margin-bottom: 15px; } }
  &__line {
    margin-bottom: 15px;
    @include sm {
      margin-bottom: 10px; }
    .line-sm {
      transform: translate(0, 0); } } }
