h1, h2, h3, h4, h5, h6 {
  font-weight: 400; }

.title {
  @include css-lock(25, 36, 576, 1199, font-size);
  color: $black;
  @include reg;
  line-height: 1.2;
  text-transform: uppercase;
  strong {
    @include bold;
    color: $red; }
  &--white {
    color: $white; }
  &--red {
    color: $red-accent; }
  &--bold {
    @include bold; }
  &--ttn {
    text-transform: none; } }

.h1, h1 {
  font-size: 36px;
  color: $white;
  @include bold;
  line-height: 1.2;
  text-transform: uppercase;
  small {
    @include light; }
  &--text {
    color: $c-text; }
  &--ttn {
    text-transform: none; } }

.h2, h2 {
  text-transform: uppercase;
  color: $white;
  @include bold;
  font-size: 60px;
  line-height: 1;
  @include css-lock(30, 80, 320, 1199, font-size);

  &--fu {
    @include css-lock(30, 60, 320, 1199, font-size); }

  &--md {
    @include css-lock(28, 48, 576, 1199, font-size); }
  &--ttn {
    text-transform: none; }
  &--text {
    color: $c-text; }
  &--red {
    color: $red-accent;
    small {
      color: $c-text; } }
  &--small-ttn {
    small {
      text-transform: none; } } }

.h2-subttl {
  font-size: 32px;
  @include light;
  color: $white;
  line-height: 1.2;
  @include css-lock(18, 32, 320, 1199, font-size); }

.h3, h3 {
  @include bold;
  @include css-lock(24, 28, 576, 1199, font-size);
  line-height: 1;
  @include xllopen {
 }    // font-size: 28px
  &--blue {
    color: $blue; }
  &--ttu {
    text-transform: uppercase; }
  &--md {
    @include css-lock(26, 36, 576, 1199, font-size); }
  &--light {
    @include light; }
  &--sm {
    @include bold;
    text-transform: uppercase;
    @include css-lock(18, 21, 576, 1199, font-size); }
  &--ttn {
    text-transform: none; } }

.h3-subttl {
  // font-size: 21px
  color: $black;
  text-transform: uppercase;
  @include light;
  font-size: 16px;
  // +css-lock(18, 16, 576, 1199, font-size)
  line-height: 1;
  @include xllopen {
 } }    // font-size: 16px

.h4, h4 {
  text-transform: uppercase;
  @include light;
  color: $white;
  font-size: 18px;
  line-height: 1.2;

  &--blue {
    color: $blue; }
  &--ttn {
    text-transform: none; }
  &--xl {
    @include bold;
    @include css-lock(21, 28, 576, 1199, font-size); }
  &--lg {
    @include bold;
    @include css-lock(21, 26, 576, 1199, font-size); }
  &--md {
    @include reg;
    @include css-lock(21, 24, 576, 1199, font-size); }
  &--black {
    color: $black; }
  &--text {
    color: $c-text; } }

.h5, h5 {
  font-size: 16px;
  @include bold;
  text-transform: uppercase;
  line-height: 1.2;
  &--white {
    color: $white; }
  &--ttn {
    text-transform: none; }
  &--blue {
    color: $blue; } }

.h6, h6 {}

.text {
  font-size: $fz;
  text-align: justify;
  @include light;
  color: $c-text;
  @include md {
    font-size: $fz-md; }
  &--thin {
    @include thin; }
  &--gray {
    color: $gray;
    strong {
      color: $c-text; } }
  &--white {
    color: $white; }
  &--left {
    text-align: left; }
  &--ttu {
    text-transform: uppercase; } }

.title-under {
  position: relative;
  padding-bottom: 30px;
  @include after {
    border-bottom: 1px solid  $red;
    width: 50px;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 0;
    position: absolute; }
  @include before {
    border-bottom: 1px solid  $red;
    width: 100px;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 8px;
    position: absolute; }

  &--sm {
    padding-bottom: 15px;
    @include before {
      display: none; }
    @include after {
      width: 80px; } }
  &--md {
    padding-bottom: 25px;
    @include before {
      display: none; }
    @include after {
      left: 0;
      transform: translate(0, 0);
      border-bottom-width: 5px;
      width: 80px;
      border-color: $red-accent; } }
  &--left {
    @include after {
      transform: translate(0, 0);
      left: 0; } }
  &--xs {
    padding-bottom: 10px;
    @include before {
      display: none; }
    @include after {
      left: 0;
      transform: translate(0, 0);
      width: 20px; } }
  &--white {
    @include before {
      border-color: $white; }
    @include after {
      border-color: $white; } } }


.title-lines {
  @extend .title-under;
  padding: 0;
  height: 9px;
  margin-bottom: calc(var(--main-padding-md, 25px) / 2); }
