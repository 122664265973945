.principles {
  --principles-right-width: 480px;
  position: relative;
  z-index: 1;
  padding-bottom: var(--section-padding-lg, 100px);
  @include r(1700) {
    --principles-right-width: 400px; }
  @include lg {
    --principles-right-width: 350px; }
  @include sm {
    --principles-right-width: 250px;
    padding-bottom: 0; }
  &__principles {
    position: relative;
    @include lg {
      max-width: calc(100% - (var(--principles-right-width, 480px) + var(--section-padding, 40px) + 30px));
      padding-left: var(--section-padding-md, 65px); }
    @include xs {
      max-width: none;
      margin-bottom: 15px;
      padding-right: var(--section-padding-md, 65px); } }
  &__title {
    max-width: calc(100% - (var(--principles-right-width, 480px) + var(--section-padding, 40px) - var(--section-padding-md, 65px) + 30px));
    @include xs {
      max-width: none; } }
  &__right {
    position: absolute;
    @include cover;
    top: calc(0px - var(--section-padding-lg, 100px));
    right: var(--section-padding, 40px);
    bottom: 0;
    width: var(--principles-right-width, 480px);
    @include flex(flex-end, normal, column);
    @include xs {
      position: relative;
      top: auto;
      bottom: auto;
      width: 100%;
      right: auto;
      background-image: none !important; } }

  &__right-block {
    background-color: $black-light;
    padding: 45px 30px;
    z-index: 1;
    position: relative;
    @include lg {
      padding: 35px 20px; }
    @include md {
      padding: 15px; } }

  &__right-title {
    margin-bottom: 15px;
    @include lg {
      margin-bottom: 10px; } }
  &__right-word {
    position: absolute;
    bottom: 5%;
    left: 0;
    z-index: 0;
    @include bold;
    @include css-lock(80, 160, 576, 1199, font-size);
    color: $red-lighten;
    @include rotate(-90);
    text-transform: uppercase;
    transform-origin: 10% 70%;
    @include xs {
      display: none; } } }


.principle {
  --principle-line-width: 130px;
  --principle-line-gap: 20px;
  position: relative;
  opacity: 0;
  @include notlast {
    margin-bottom: var(--section-padding-lg, 100px); }
  @include lg {
    --principle-line-width: 90px; }
  @include md {
    --principle-line-width: 5px;
    --principle-line-gap: 10px; }
  @include before {
    position: absolute;
    background-color: $white;
    height: 1px;
    width: var(--principle-line-width, 130px);
    top: 30px;
    right: calc(100% + var(--principle-line-gap, 20px)); }
  &:nth-child(1) {
    left: $left-5-1;
    max-width: 280px;
    @include xll {
      left: $left-5-1; }
    @include lg {
      left: calc(var(--principle-line-width, 130px) + var(--principle-line-gap, 20px)); } }
  &:nth-child(2) {
    left: $left-5-2;
    max-width: 430px;
    @include xll {
      left: $left-5-1; }
    @include lg {
      left: calc(var(--principle-line-width, 130px) + var(--principle-line-gap, 20px));
      max-width: 380px; } }
  &:nth-child(3) {
    left: $left-5-3;
    max-width: 340px;
    @include xll {
      left: $left-5-1; }
    @include lg {
      left: calc(var(--principle-line-width, 130px) + var(--principle-line-gap, 20px)); } }
  &__text {
    ul {}
    li {
      position: relative;
      &:before {
        content: '-';
        margin-right: 25px; } } }
  &__letter {
    position: absolute;
    margin-top: -.4em;
    top: 0;
    left: 0;
    z-index: 0;
    overflow: hidden;
    @include md {
      display: none; }
    .text-stroke {
      position: relative;
      left: -.15em; } } }
