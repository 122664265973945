.news {
  @include sm {
    margin-left: calc(0px - var(--main-padding, 35px));
    margin-right: calc(0px - var(--main-padding, 35px)); }
  &__items {
    margin-bottom: calc(var(--main-padding-md, 45px)/2); }
  &__btn {
    text-align: center; } }


.news-item {
  --news-item-margin: 20px;
  --news-item-padding-vertical: 125px;
  --news-item-padding-horizontal: 50px;
  $this: &;
  @include flex();
  background-color: $c-text;
  @include hover {
    #{$this}__img-bg {
      @include scale(1); } }
  @include xl {
    --news-item-margin: 15px;
    --news-item-padding-vertical: 100px;
    --news-item-padding-horizontal: 40px; }
  @include lg {
    --news-item-padding-vertical: 80px;
    --news-item-padding-horizontal: 30px; }
  @include sm {
    --news-item-padding-vertical: 30px;
    flex-direction: column; }
  @include xs {
    --news-item-padding-vertical: 20px;
    --news-item-padding-horizontal: 15px;
    --news-item-margin: 10px; }
  &__img,
  &__content {
    width: 50%;
    @include flex(center, normal, column);
    @include sm {
      width: auto; } }
  &__img {
    position: relative;
    overflow: hidden;
    background-color: $gray;
    @include sm {
      padding-top: 30%;
      order: 0; }
    @include xs {
      padding-top: 50%; } }
  &__img-bg {
    @include cover;
    @include coverdiv;
    @include scale(1.1);
    @include tr(transform .3s); }
  &__title {
    margin-bottom: var(--news-item-margin, 20px); }
  &__text {
    margin-bottom: var(--news-item-margin, 20px);
    max-height: 70px; }
  &__content {
    padding: var(--news-item-padding-vertical, 125px) var(--news-item-padding-horizontal, 50px);
    @include sm {
      order: 1; } }

  &--red {
    background-color: $red-accent; } }
