.aside {
  $this: &;
  background-color: $white;
  width: $aside-width;
  // position: relative
  @include tr(transform .3s);
  @include lg {
    height: 100%;
    background-color: $white;
    #{$this}__inner {
      overflow: auto; } }
  @include xss {
    width: $aside-width-mobile; }

  &__block {
    &:last-child {
      border-bottom: 1px solid  $c-border; } }
  &__toggle {
    position: absolute;
    z-index: 1;
    left: 100%;
    top: 0;
    height: 37px;
    width: 37px;
    background-color: $red;
    @include flex(center, center);
    @include before {
      @include icon-arrow(8, 8, 1, $white, 45); }
    &.is-active {
      @include before {
        @include icon-arrow(8, 8, 1, $white, 225); } } }
  &__title {
    padding: 8px 15px;
    background-color: $c-text;
    display: block;
    width: 100%;
    position: relative;
    @include after {
      top: 1em;
      right: 15px;
      @include icon-arrow(8, 8, 1, currentColor, -225);
      position: absolute;
      @include tr(transform .3s); }
    &.is-active {
      @include after {
        @include icon-arrow(8, 8, 1, currentColor, -45);
        top: .8em; } } }
  &__title-main {
    background-color: $red;
    @include after {
      display: none; } }
  &__list-title {
    border-bottom: 1px solid  $c-border;
    display: block;
    padding: 8px 15px;
    padding-right: 30px;
    text-transform: uppercase;
    position: relative;
    &.is-active {
      @include before {
        display: block; }
      @include after {
        @include icon-arrow(8, 8, 1, $red, -225);
        top: 1em; } }
    @include before {
      width: 39px;
      height: 100%;
      background-color: $gray-light;
      top: 0;
      right: 0;
      position: absolute;
      display: none; }
    @include after {
      top: .8em;
      right: 15px;
      @include icon-arrow(8, 8, 1, $red, -45);
      position: absolute;
      @include tr(transform .3s); } }
  &__list {
    max-height: 400px;
    overflow: auto; }
  &__list-items-accordion {
    display: none; }

  &__list-title-item {
    background-color: $gray-light;
    @include notlast {
      border-bottom: 1px solid  $c-border; }
    .checkbox {
      // span
 } }      //   +coverdiv
  &__list-title-checkbox {
    background-color: $white;
    @include notlast {
      border-bottom: 1px solid  $c-border; } } }
