.article {
  .h3, h3, .h1, h2 {
    text-align: center;
    margin-bottom: 15px; }
  p {
    @extend .text;
    @extend .text--gray;
    margin-bottom: 20px;
    @include sm {
      margin-bottom: 10px; } }
  img {
    margin-bottom: 40px;
    width: 100%;
    @include sm {
      margin-bottom: 20px; } }
  figure {
    position: relative;
    // margin-bottom: 40px
    img {
      margin-bottom: 0; }
    figcaption {
      position: absolute;
      z-index: 1;
      bottom: 15px;
      right: 15px;
      color: $white; } }
  blockquote {
    @include thinit;
    background-color: $white;
    padding: 30px;
    border: 1px solid  $gray-light;
    border-left: 4px solid  $red-accent;
    position: relative;
    margin-bottom: 35px;
    @include md {
      padding: 20px; }
    @include sm {
      margin-bottom: 15px; }
    p {
      position: relative;
      z-index: 1;
      @include thinit;
      color: $c-text;
      margin-bottom: 0; }
    .icon {
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 0;
      fill: $gray-light;
      font-size: 50px; } } }


.more-articles {
  --more-articles-col-gap: 15px;
  @include flex(flex-start, normal, row, wrap);
  margin-left: calc(0px - var(--more-articles-col-gap, 15px));
  margin-right: calc(0px - var(--more-articles-col-gap, 15px));
  @include md {
    --more-articles-col-gap: 10px; }
  .article-sm {
    margin-right: var(--more-articles-col-gap, 15px);
    margin-left: var(--more-articles-col-gap, 15px);
    width: calc(25% - (var(--more-articles-col-gap, 15px) * 2));
    @include sm {
      width: calc(50% - (var(--more-articles-col-gap, 15px) * 2));
      margin-bottom: var(--more-articles-col-gap, 15px); }
    @include xs {
      width: calc(100% - (var(--more-articles-col-gap, 15px) * 2)); } } }

.article-sm {
  $this: &;
  display: block;
  @include hover {
    #{$this}__img {
      @include scale(1.1);
      @include before {
        visibility: visible;
        opacity: 1; } }
    #{$this}__content {
      transform: translate(0, 20px); } }
  &__img {
    display: block;
    @include cover;
    padding-top: 120%;
    margin-bottom: 15px;
    @include tr(transform .3s);
    position: relative;
    @include before {
      @include coverdiv;
      background-color: rgba($red-accent, .75);
      z-index: 1;
      visibility: hidden;
      opacity: 0;
      @include tr(opacity .3s, visibility .3s); }
    @include xs {
      padding-top: 60%; } }
  &__date {
    margin-bottom: 10px;
    color: $gray;
    display: block;
    font-size: $fz-sm; }
  &__title {
    margin-bottom: 10px;
    display: block; }
  &__text {
    max-height: 4.1em;
    display: block; }
  &__content {
    display: block;
    @include tr(transform .3s); } }
