$c-text: #191919;

$black: #000;
$black-light: #292929;

$white: #fff;
// $red: #b9282e
$red: #c92116;
$red-light: #ff6d73;
$red-lighten: #d6372d;
$red-accent: #c92116;

$blue: #183b77;
$blue-light: #1981c5;
$blue-lighten: #36c0e2;

$gray: #939598;
$gray-light: #ececf2;
$gray-lighten: #e8e8e8;
$gray-lightest: #f2f2f2;
$gray-darken: #d7d7d7;
$gray-dark: #626262;
$c-border: #d7d7d7;

$green: #7ac143;
$orange: #f8991d;

$white-transp: rgba(#fff, .15);

$header-height: 97px;
$header-height-md: 65px;
$header-height-mob: 45px;
$subnav-height: 54px;

$footer-height: 65px;
$footer-height-mob: 93px;
$footer-height-xs: 112px;

$left-1: percentage(380/1920);
$left-2: percentage(850/1920);
$left-3: percentage(1370/1920);

$left-1-fucked-up: percentage(440/1920);
$left-2-fucked-up: percentage(930/1920);
$left-3-fucked-up: percentage(1420/1920);

$left-1-fucked-up-mob: calc(#{$left-1-fucked-up} - 5%);
$left-2-fucked-up-mob: calc(#{$left-2-fucked-up} - 5%);
$left-3-fucked-up-mob: calc(#{$left-3-fucked-up} - 5%);


$left-7-1: percentage(440/1920);
$left-7-2: percentage(595/1920);
$left-7-3: percentage(755/1920);
$left-7-4: percentage(930/1920);
$left-7-5: percentage(1095/1920);
$left-7-6: percentage(1250/1920);
$left-7-7: percentage(1420/1920);

$left-5-1: percentage(440/1920);
$left-5-2: percentage(675/1920);
$left-5-3: percentage(930/1920);
$left-5-4: percentage(1175/1920);
$left-5-5: percentage(1420/1920);

$width-1: percentage(380/1920);
$width-2: percentage(470/1920);
$width-3: percentage(520/1920);
$width-4: percentage(550/1920);

$width-1-fucked-up: percentage(440/1920);
$width-2-fucked-up: percentage(490/1920);
$width-3-fucked-up: percentage(490/1920);
$width-4-fucked-up: percentage(500/1920);

$width-1-fucked-up-2: percentage(690/1920);
$width-2-fucked-up-2: percentage(640/1920);
$width-3-fucked-up-2: percentage(590/1920);

$fz:    16px;
$fz-md: 14px;
$fz-sm: 12px;

$aside-width: 290px;
$aside-width-mobile: 280px;

 :root {
  --section-padding: 40px;
  --section-padding-md: 65px;
  --section-padding-lg: 100px;
  --main-padding: 55px;
  --main-padding-md: 80px; }

@include md {
   :root {
    --section-padding: 30px;
    --section-padding-md: 40px;
    --section-padding-lg: 50px;
    --main-padding: 35px;
    --main-padding-md: 45px; } }

@include sm {
   :root {
    --section-padding: 15px;
    --section-padding-md: 15px;
    --section-padding-lg: 30px;
    --main-padding: 15px;
    --main-padding-md: 25px; } }
