.footer {
  background-color: $white;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  @include flex(center, center);
  color: $gray-dark;
  height: $footer-height;
  @include xs {
    display: block;
    height: $footer-height-mob; }
  @include r(395) {
    height: $footer-height-xs; }
  &__copy {
    text-transform: uppercase;
    font-size: $fz-md;
    margin-right: 20px;
    @include xs {
      margin-bottom: 10px; } }

  &--gray {
    background-color: $gray-lightest; } }

