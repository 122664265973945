.feature {
  opacity: 0;
  @include md {
    @include notlast {
      margin-bottom: 15px; } }
  &__nmb {
    position: relative;
    border-bottom: 1px solid  $red;
    padding-bottom: 20px;
    width: 18px;
    margin-bottom: 15px;
    span {
      display: inline-block;
      @include rotate(-90);
      @include semi; } } }
