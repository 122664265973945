.slider {
  $this: &;
  &__wrap {
    --content-padding: 50px;
    --arrows-gap: 15px;
    position: relative;
    @include md {
      --arrows-gap: 10px;
      --content-padding: 15px; } }

  &__prev,
  &__next {
    position: absolute;
    top: 50%;
    background-color: transparent;
    z-index: 1; }
  &__prev {
    @include icon-arrow(15, 15, 1, $c-text, 45);
    display: inline-block;
    transform: translate(0, -50%) rotate (45deg);
    left: var(--arrows-gap, 10px); }
  &__next {
    @include icon-arrow(15, 15, 1, $c-text, -135);
    display: inline-block;
    transform: translate(0, -50%) rotate (45deg);
    right: var(--arrows-gap, 10px); }

  &--similar {
    .swiper-slide {
      height: auto;
      box-sizing: border-box; }
    &__wrap {
      @include sm {
        margin-left: calc(0px - var(--content-padding, 50px));
        margin-right: calc(0px - var(--content-padding, 50px));
        padding-left: var(--content-padding, 50px);
        padding-right: var(--content-padding, 50px); } } }

  &--assort {
    &__wrap {
      padding-top: 45px;
      position: relative;
      @include md {
        padding-top: 25px; }
      @include sm {
        padding-top: 15px; }
      .swiper-slide {
        z-index:  0; }
      .swiper-container {
        @include sm {
          // overflow: visible
          margin-left: calc(0px - var(--content-padding, 50px));
          margin-right: calc(0px - var(--content-padding, 50px));
          padding-left: var(--content-padding, 50px);
          padding-right: var(--content-padding, 50px); } } }

    &__btns {
      position: absolute;
      // bottom: 50px
      bottom: 10%;
      display: inline-block;
      left: calc(30% - 50px);
      z-index: 1;
      @include xll {
        bottom: 20px; }
      @include md {
        bottom: auto;
        top: 60%;
        left: var(--section-padding, 40px); }
      @include sm {
        left: 0;
        top: 260px; }
      @include xs {
        top: 200px; } }
    &__counter {
      position: absolute;
      top: 0;
      left: -.5em;
      @include md {
        display: none; } }
    &__counter-sm {
      position: absolute;
      left: 0;
      bottom: 100px;
      @include xll {
        bottom: 0; } } }
  &--standart {
    &__wrap {
      @extend .slider--assort__wrap;
      padding-top: 0;
      @include sm {
        .swiper-container {
          margin-left: 0;
          margin-right: 0;
          padding-left: 0;
          padding-right: 0; } }

      .swiper-slide {
        // opacity: 0
        z-index: 0; } }
    &__btns {
      bottom: 170px;
      position: absolute;
      z-index: 2;
      left: 57%;
      @include md {
        bottom: 100px; }
      @include sm {
        bottom: auto;
        top: 200px;
        left: var(--section-padding, 40px); } }
    &__counter-sm {
      position: absolute;
      bottom: 20px;
      left: 60%;
      z-index: 2;
      @include lg {
        left: 58%; }
      @include md {
        left: 55%; }
      @include sm {
        left: auto;
        right: var(--section-padding, 40px); }
      @include xs {
        right: auto;
        left: var(--section-padding-lg, 100px); } } }
  &--thumbs {
    // box-sizing: border-box
    .slide {
      text-align: center; } }
  &--contacts {
    box-sizing: border-box;
    &__wrap {
      #{$this}__prev,
      #{$this}__next {
        // height: 100%
        width: 30%;
        background-color: transparent;
        width: 400px;
        top: 30px;
        bottom: 85px;
        transform: translate(0, 0);
        border: 0;
        height: auto;
        width: calc(#{percentage(510/1920) / 2} - 40px);
        @include md {
          width: calc(#{percentage(510/1920) / 2} - 20px);
          top: 20px;
          bottom: 100px; }
        @include sm {
          width: calc(#{percentage(510/1920) / 2} - 10px);
          bottom: 125px; } }
      #{$this}__prev {
        left: 0; }
      #{$this}__next {
        right: 0; } }
    .slide {
      width: percentage(1410/1920);
      // +vertical
      height: auto;
      &.swiper-slide-active {
        &:before {
          display: none; }
        .slide__title {
          display: block; }
        .slide__bottom {
          visibility: visible;
          opacity: 1; }
        .slide__img {
          transform: scaleY(1);
          display: block;
          filter: grayscale(0); } }

      &__img {
        @include cover;
        padding-top: 55%;
        transform: scaleY(.9);
        position: relative;
        width: 100%;
        filter: grayscale(100%);
        @include tr(transform .3s, filter .3s);
        @include sm {
          padding-top: 65%; }
        @include xs {
          padding-top: 75%; } }
      &__title {
        position: absolute;
        bottom: 5%;
        left: 5%;
        display: none; }
      &__btn {
        text-align: center;
        @include lg {
          .btn {
            min-width: 0;
            width: 100%; } } }
      &__bottom {
        padding-top: 20px;
        @include flex(space-between, center, row, wrap);
        visibility: hidden;
        opacity: 0;
        @include tr(visibility .3s, opacity .3s, transform .3s);
        @include md {
 }          // display: block
        .text, .slide__btn {
          width: 33.33333%; }
        .slide__btn {
          @include md {
            order: 2;
            width: 100%; } }
        .text {
          padding-right: 10px;
          @include md {
            margin-bottom: 10px; }
          &:first-child {
            @include md {
              order: 0;
              width: 50%; }
            @include sm {
              width: 100%; } }

          &:last-child {
            text-align: right;
            padding-right: 0;
            @include md {
              order: 1;
              width: 50%; }
            @include sm {
              width: 100%;
              text-align: left; } } } } } } }


.assort-slide {
  // display: flex !important
  @include flex(flex-start, center);
  // background-color: $white
  height: 100%;
  position: relative;
  box-sizing: border-box;
  @include xll {
    padding-bottom: 30px; }
  @include md {
   flex-direction: column; }
  @include sm {
    margin-left: calc(0px - var(--section-padding-md, 65px));
    margin-right: calc(0px - var(--section-padding-md, 65px)); }
  &__title {
    margin-bottom: 25px;
    opacity: 0;
    // transform: translateY(100px)
    @include md {
      margin-bottom: 15px; } }
  &__content {
    width: 30%;
    padding-right: 5%;
    @include md {
      width: 100%;
      order: 1; }
    @include sm {
      padding-left: var(--section-padding-md, 65px);
      padding-right: var(--section-padding-md, 65px); } }
  &__img {
    width: 70%;
    @include cover;
    padding-top: 40%;
    // height: 100%
    transform: translateX(100%);
    @include xll {
      // height: calc(100% + 30px)
      margin-bottom: -30px; }
    @include md {
      width: 100%;
      order: 0;
      margin-bottom: 20px;
      // height: auto
      // height: 60%
 }      // padding-top: 35%
    @include sm {
      padding-top: 0;
      height: 270px; }
    @include xs {
      // padding-top: 0
      height: 200px;
      margin-bottom: 30px; } }
  &__text {
    text-align: left;
    opacity: 0;
 } }    // transform: translateY(100px)

.arrow {
  --arrow-size: 100px;
  --arrow-font-size: 40px;
  width: var(--arrow-size, 100px);
  height: calc(var(--arrow-size, 100px) / 2);
  background-color: $red;
  color: $white;
  @include inline-flex(center, center);
  font-size: var(--arrow-font-size, 40px);
  @include md {
    --arrow-size: 75px;
    --arrow-font-size: 35px; }
  .icon {
    fill: currentColor; }
  &.swiper-button-disabled {
    opacity: .5; }
  &--next {
    .icon {
      @include rotate(180); } }
  &--white {
    background-color: $white;
    color: $red; } }

.slider-counter-sm {
  $this: &;
  position: relative;
  @include thin;
  color: $gray;
  .swiper-pagination-progressbar-fill {
    background-color: $red; }
  &__current {
    display: inline-block;
    vertical-align: middle; }
  &__all {
    display: inline-block;
    vertical-align: middle; }
  &__line {
    display: inline-block;
    vertical-align: middle;
    width: 60px;
    background-color: currentColor;
    height: 1px;
    position: relative;
    overflow: hidden; }

  &--white {
    .swiper-pagination-progressbar-fill {
      background-color: $white; }
    #{$this}__current {
      color: $white; }
    #{$this}__line {
      background-color: $red-light; }
    #{$this}__all {
      color: $red-light; } } }

.standart-slide {
  box-sizing: border-box;
  position: relative;
  padding-top: 15%;
  @include flex(flex-end, normal, column);
  @include sm {
    padding-top: 0; }
  &__title {
    margin-bottom: 15px;
    @include md {
      margin-bottom: 10px; } }
  &__block {
    background-color: $red-accent;
    padding-top: 75px;
    padding-bottom: 60px;
    padding-left: $left-1;
    padding-right: 12%;
    position: relative;
    z-index: 1;
    max-width: percentage(1310/1920);
    // transform: translateY(100%)
    opacity: 0;
    @include lg {
      padding-left: 15%; }
    @include md {
      padding-left: var(--section-padding-lg, 100px);
      padding-top: 40px;
      padding-bottom: 30px; }
    @include sm {
      max-width: none;
      height: 60%;
      padding-top: 50px;
      padding-left: var(--section-padding, 40px);
      padding-right: var(--section-padding, 40px); }
    @include xs {
      padding-bottom: 50px; } }
  &__date {
    color: $red-light;
    font-size: $fz-md;
    margin-bottom: 20px;
    @include md {
      margin-bottom: 15px; } }
  &__text {
    margin-bottom: 25px;
    @include md {
      margin-bottom: 20px; } }
  &__img {
    @include cover;
    height: calc(100% - 60px);
    width: calc(100% - #{$width-1-fucked-up});
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    transform: translateX(100%);
    @include sm {
      position: relative;
      width: 100%;
      height: 200px; } } }

