.delivery-form {
  max-width: 1070px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
  &__title {
    text-align: center; }
  &__form-inner {
    margin-left: -15px;
    margin-right: -15px;
    @include flex(flex-start, normal, row, wrap); }
  &__col {
    padding-left: 15px;
    padding-right: 15px;
    width: 33.33333%;
    .select {
      padding-top: 17px; }
    @include sm {
      width: 50%;
      &:last-child {
        width: 100%; } }
    @include xs {
      width: 100%; } }
  &__field {
    .btn {
      margin-bottom: 15px;
      margin-top: 20px; }
    .text {
      text-align: center; }
    a:not(.btn) {
      text-decoration: underline; } } }
