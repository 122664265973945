.social {
  color: $white;
  li {
    display: inline-block;
    margin-left: 3px;
    margin-right: 3px;
    @include lg {
      display: inline-block;
      margin-left: 3px;
      margin-right: 3px;
      &:first-child {
        a {
          border: 1px solid  currentColor; } } } }
  a {
    @include flex(center, center);
    border: 1px solid  currentColor;
    font-size: 15px;
    color: $white;
    height: 23px;
    width: 23px;
    // padding: 0px 7px
    padding: 0 !important;
    text-align: center;
    @include tr(border-color .3s, background-color .3s);
    @include hover {
      border-color: $red;
      background-color: $red;
      .icon {
        fill: $white; } }
    .icon {
      fill: currentColor;
      @include tr(fill .3s); } }

  &--footer {
    color: $gray-dark;
    a {
      color: $gray-dark; } } }
