.hero {
  @include cover;
  min-height: 50vh;
  padding-top: $header-height;
  @include flex(center, center);
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  opacity: 0;
  @include before {
    @include coverdiv;
    background-color: rgba($black, .4);
    z-index: 0; }
  @include xl {
    padding-top: $header-height-md; }
  @include lg {
    padding-top: $header-height-mob; }
  &__block {
    text-align: center;
    position: relative;
    z-index: 1; }
  &__title {
    margin-bottom: 10px;
    opacity: 0; }
  &__subttl {
    max-width: 930px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    overflow: hidden;
    .h2-subttl {
      transform: translateY(-110%); } }
  &__breadcrumbs {
    opacity: 0; }
  &__breadcrumbs-top {
    position: absolute;
    top: calc(#{$header-height} + 10px);
    left: var(--section-padding, 40px);
    z-index: 10;
    @include xl {
      top: calc(#{$header-height-md} + 10px); }
    @include lg {
      top: calc(#{$header-height-mob} + 10px); } }

  &--lg {
    min-height: 75vh;
    @include sm {
      min-height: 50vh; } }
  &--light {
    @include before {
      display: none; } } }
