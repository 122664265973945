.fullpage {
  overflow: hidden;
  &__nav {
    position: fixed;
    z-index: 10;
    right: 0;
    display: inline-block;
    top: 50%;
    transform: translate(0, -50%);
    @include xs {
      display: none; }
    @include rh(400) {
      display: none; }
    a {
      color: rgba($white, .5);
      padding-top: 10px;
      padding-bottom: 10px;
      @include light;
      font-size: 0;
      line-height: 14px;
      position: relative;
      display: block;
      text-align: right;
      @include tr(color .3s);
      &.is-active {
        color: $white;
        font-size: 11px;
        @include after {
          width: 53px; } }
      @include hover {
        color: $white;
        font-size: 11px;
        @include after {
          width: 53px; } }
      @include after {
        height: 1px;
        background-color: currentColor;
        width: 30px;
        display: inline-block;
        vertical-align: super;
        margin-left: 10px;
        @include tr(width .3s); } } }
  &__copy {
    position: fixed;
    z-index: 10;
    bottom: 30px;
    right: 30px;
    @include md {
      right: 0;
      width: 100%;
      text-align: center; }
    @include xs {
      bottom: 15px; }
    @include rh(550) {
      display: none; } } }


.fullpage-section {
  $this: &;
  height: 100vh;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  // visibility: hidden
  // opacity: 0
  position: absolute;
  top: 0;
  left: 0;
  padding-top: $header-height;
  @include cover;
  @include flex(center, center, column);
  @include xl {
    padding-top: $header-height-md; }
  @include lg {
    padding-top: $header-height-mob; }
  @include md {
    padding-bottom: 50px; }
  @include xs {
    // justify-content: space-between
    padding-bottom: 155px; }

  @include rh(550) {
    padding-bottom: 15px !important; }
  &.is-active {
    visibility: visible;
    opacity: 1; }
  &__descr {
    position: relative;
    width: 100%;
    @include xs {
      margin-bottom: 15px; }
    div {
      color: $white;
      text-transform: uppercase;
      @include css-lock(16, 30, 576, 1199, font-size);
      position: absolute;
      transform: translate(0, -100%);
      @include bold;
      @include xs {
        position: static;
        text-align: center;
        font-size: 24px;
        transform: translate(0, 0); }
      &:nth-child(1) {
        left: $left-1; }
      &:nth-child(2) {
        left: $left-2; }
      &:nth-child(3) {
        left: $left-3; } } }
  &__title {
    @include rh(550) {
      margin-bottom: 5px; }
    .h2 {
      opacity: 0; }
    .h2-subttl {
      transform: translate(0, -100%); } }
  &__subttl {
    overflow: hidden; }
  &__block {
    position: relative;
    z-index: 1;
    text-align: center;
    @include flex(flex-start, center, column);
    overflow: hidden;
    .line-sm {
      margin-bottom: 25px;
      transform: translate(0, -100%);
      @include rh(550) {
        display: none; } }
    .btn {
      transform: translate(0, -105%); } }
  &__block-bottom {
    position: absolute;
    bottom: 185px;
    @include xs {
      position: static; } }
  &__scroll {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translate(-50%, 0);
    .scroll-down {
      opacity: 0; }
    @include md {
      bottom: 70px; }
    @include xs {
 }      // position: static
    @include rh(550) {
      display: none; } }
  &__copy {
    opacity: 0; }

  &--hero {
    position: relative;
    opacity: 0;
    #{$this}__descr {
      div {
        &:nth-child(1) {
          left: $left-1-fucked-up; }
        &:nth-child(2) {
          left: $left-2-fucked-up; }
        &:nth-child(3) {
          left: $left-3-fucked-up; } } } } }
