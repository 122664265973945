.nav {
  font-size: 0;
  @include xs {
    width: 100%; }
  li {
    display: inline-block;
    // position: relative
    &:first-child {
      position: static; }
    @include lg {
      display: block;
      margin-left: 0;
      margin-right: 0;
      margin-top: 15px;
      margin-bottom: 15px;
      &:first-child {
        a {
          border-bottom: 4px solid  $red; } } } }
  a {
    text-transform: uppercase;
    color: $white;
    display: block;
    @include semi;
    font-size: 16px;
    padding: 10px 30px 39px;
    @include tr(background-color .3s);
    @include hover {
      background-color: $red; }
    @include xl {
      padding: 21px 30px; }
    @include lg {
      font-size: 21px;
      background-color: transparent;
      padding: 0;
      &.is-active {
        background-color: transparent; } }

    &.is-active {
      background-color: $red; }
    &.is-hovered {
      background-color: $red; } } }

.nav {
  li {
    &:hover {
      > ul {
        visibility: visible;
        opacity: 1; } }
    > ul {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      @include tr(opacity .3s, visibility .3s);
      visibility: hidden;
      opacity: 0;
      @include lg {
        position: relative;
        top: auto;
        visibility: visible;
        opacity: 1; }
      @include before {
        width: 100%;
        height: 4px;
        background-color: $red;
        top: 0;
        left: 0;
        position: absolute;
        display: block;
        @include tr(transform .3s);
        z-index: 1;
        @include lg {
          display: none; } }
      li {
        width: 25%;
        @include lg {
          width: 100% !important;
          a {
            background-color: transparent; } }
        &:nth-child(1) {
          width: $width-1; }
        &:nth-child(2) {
          width: $width-2; }
        &:nth-child(3) {
          width: $width-3; }
        &:nth-child(4) {
          width: $width-4;
          @include lg {
            margin-bottom: 0;
            a {
              background-color: transparent; } }
          a {
            background-color: rgba(108, 83, 69, 0.502); } } }

      a {
        padding: 15px;
        border: 1px solid $white-transp;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.502);
        @include reg;
        @include tr(background-color .3s, color .3s);
        &.is-active {
          color: #2a3d75;
          background-color: $white;
          border-bottom: 1px solid rgba($black, .15);
          @include lg {
            color: $red-light; } }
        @include hover {
          color: #2a3d75;
          background-color: $white; }
        @include xl {
          padding: 10px; }
        @include lg {
          padding: 5px;
          border: 0;
          border-bottom: 1px solid $white-transp !important; } } } }

  // &--sub
  //// li
  ////   width: 25%
  ////   +lg
  ////     width: 100% !important
  ////     a
  ////       background-color: transparent
  ////   &:nth-child(1)
  ////     width: $width-1
  ////   &:nth-child(2)
  ////     width: $width-2
  ////   &:nth-child(3)
  ////     width: $width-3
  ////   &:nth-child(4)
  ////     width: $width-4
  ////     +lg
  ////       margin-bottom: 0
  ////       a
  ////         background-color: transparent
  ////     a
  ////       background-color: rgba(108, 83, 69, 0.502)

  //// a
  ////   padding: 15px
  ////   border: 1px solid $white-transp
  ////   text-align: center
  ////   background-color: rgba(0, 0, 0, 0.502)
  ////   +reg
  ////   +tr(background-color .3s, color .3s)
  ////   &.is-active
  ////     color: #2a3d75
  ////     background-color: $white
  ////     border-bottom: 1px solid rgba($black, .15)
  ////     +lg
  ////       color: $red-light
  ////   +hover
  ////     color: #2a3d75
  ////     background-color: $white
  ////   +xl
  ////     padding: 10px
  ////   +lg
  ////     padding: 5px
  ////     border: 0
  ////     border-bottom: 1px solid $white-transp !important

  // &--sub-equal
  //   li
  //     &:nth-child(1)
  //       width: 25%
  //     &:nth-child(2)
  //       width: 25%
  //     &:nth-child(3)
  //       width: 25%
  //     &:nth-child(4)
 }  //       width: 25%


