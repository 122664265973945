.catalog-item {
  --main-color: #{$black};
  @include flex();
  position: relative;
  padding: 30px;
  @include tr(background-color .3s, box-shadow .3s);
  @include xl {
    padding-left: 15px;
    padding-right: 15px; }
  @include hover {
    background-color: rgba(#ecebf0, .5);
    box-shadow: 0 0 15px rgba($black, .3); }

  @include md {
    display: block; }
  &__text {
    display: block;
    @include md {
      padding-top: 30px; }
    @include xs {
      padding-top: 0; } }
  &__icons {
    display: block;
    @include sm {
      // background: rgba($white, .8)
      // border-top: 1px solid $c-border
      padding-top: 15px;
      // border-bottom: 1px solid $c-border
      margin-left: calc(0px - var(--content-padding, 15px) - 15px);
      margin-right: calc(0px - var(--content-padding, 15px) - 15px); } }
  &__img {
    width: 40%;
    display: block;
    @include xl {
 }      // width: 25%
    @include lg {
 }      // width: 30%
    @include md {
      width: 250px;
      height: 175px;
      position: absolute;
      top: 30px;
      left: 0; }
    @include xs {
      // width: 100px
      width: 100%;
      height: auto;
      position: static;
      margin-bottom: 15px; } }
  &__content {
    // flex-grow: 1
    @include flex(center, normal, column);
    width: 60%;
    @include md {
      width: 100%; } }

  &__title {
    margin-bottom: 35px;
    display: block;
    @include md {
      // margin-bottom: 25px
      padding-left: 250px;
      margin-bottom: 120px; }
    @include xs {
      padding-left: 100px;
      margin-bottom: 25px;
      padding-left: 0; } } }


.characteristics {
  @include flex(space-between, normal, row, wrap);
  @include xllopen {
    justify-content: flex-start; }
  &__item {
    text-align: center;
    margin-left: 7px;
    margin-right: 7px;
    margin-bottom: 15px;
    @include xllopen {
      width: auto;
      flex-grow: 1;
      margin-left: 0;
      margin-right: 0; }
    @include xll {
      margin-left: 0;
      margin-right: 0; }
    @include xl {
      width: auto;
      flex-grow: 1; }
    @include sm {
      width: calc(33.33333% - 14px);
      margin-bottom: 15px; }
    @include xss {
      width: calc(50% - 14px); } }
  &__icon {
    margin-bottom: 10px;
    .icon {
      font-size: 34px;
      fill: var(--main-color, #{$black}); } }
  &__item-title {
    text-transform: uppercase;
    @include light;
    font-size: 11px;
    max-width: 82px;
    margin-left: auto;
    margin-right: auto;
    color: $black;
    margin-bottom: 10px; }
  &__rating {
    text-align: center;
    color: $white;
    background-color: var(--main-color, #{$black});
    padding: 4px 4px 3px;
    @include light;
    font-size: 11px;
    .icon {
      fill: $white;
      font-size: 11px;
      position: relative;
      top: .2em; } } }
