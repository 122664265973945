@include font('proximanova-regular', 'proximanova-regular-webfont');
@include font('proximanova-bold', 'proximanova-bold-webfont');
@include font('proximanova-semibold', 'proximanova-semibold-webfont');
@include font('proximanova-light', 'proximanova-light-webfont');
@include font('proximanovat-thin', 'proximanovat-thin-webfont');
@include font('proximanova-black', 'proximanova-black-webfont');
@include font('proximanova-thinit', 'proximanova-thinit-webfont');

@mixin reg {
  font-family: 'proximanova-regular', Arial, sans-serif; }
@mixin bold {
  font-family: 'proximanova-bold', Arial, sans-serif; }
@mixin semi {
  font-family: 'proximanova-semibold', Arial, sans-serif; }
@mixin light {
  font-family: 'proximanova-light', Arial, sans-serif; }
@mixin thin {
  font-family: 'proximanovat-thin', Arial, sans-serif; }
@mixin thinit {
  font-family: 'proximanova-thinit', Arial, sans-serif; }
@mixin black {
  font-family: 'proximanova-black', Arial, sans-serif; }


body {
  background: #FFF;
  line-height: 1.4;
  font-size: $fz;
  @include light;
  color: $c-text;
  @include no-scroll;
  background-color: $black;
  @include md {
    font-size: $fz-md; } }

.out {
  overflow-x: hidden;
  &--fullpage {
    overflow: hidden;
    width: 100%;
    height: 100vh;
    @include cover; }
  &--no-hero {
    padding-top: $header-height;
    @include xl {
      padding-top: $header-height-md; }
    @include lg {
      padding-top: $header-height-mob; } } }

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
  padding-left: 15px;
  padding-right: 15px; }

img {
  max-width: 100%; }

strong {
  font-weight: 400;
  @include bold; }

small {
  font-size: inherit; }

input, textarea {
  font-family: inherit;
  @include placeholder {
    font-family: inherit;
    font-size: inherit; } }

// .is-animated
[data-loaded="true"] {
  animation: fadeIn .4s; }


.overflow-hidden {
  overflow: hidden;
  padding-right: 1px; }

.icon {
  display: inline-block; }

.logo {
  @include flex(center, center);
  width: 182px;
  height: $header-height;
  @include xl {
    height: $header-height-md;
    padding-top: 10px;
    padding-bottom: 10px;
    img {
      max-height: 100%; } }
  @include lg {
    width: 70px;
    height: auto; }
  &.is-red {
    background-color: #b80a00; } }

.copy {
  font-size: $fz-md;
  text-transform: uppercase;
  color: rgba($white, .5);
  @include thin; }

.scroll-down {
  text-align: center;
  &__title {
    text-transform: uppercase;
    color: rgba($white, .5);
    font-size: $fz-md;
    @include thin; }
  &__btn {
    display: inline-block; }
  &__mouse {
    display: block;
    position: relative;
    border: 2px solid rgba($white, .5);
    height: 45px;
    width: 26px;
    border-radius: 25px;
    @include before {
      width: 5px;
      height: 5px;
      display: inline-block;
      background-color: rgba($white, .5);
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 50%;
      transform: translate(-50%, 0); } }
  &__arr {
    display: inline-block;
    position: relative;
    height: 25px;
    width: 26px;
    margin-bottom: 15px;
    @include before {
      display: inline-block;
      @include icon-arrow(15, 15, 2, rgba($white, .5), -45);
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0) rotate(-45deg);
      animation-name: scrollDown;
      animation-duration: 1s;
      animation-iteration-count: infinite; }
    @include after {
      @extend .scroll-down__arr::before;
      top: 10px;
      animation-duration: 1s;
      animation-delay: .1s;
      animation-iteration-count: infinite; } } }

.btn {
  display: inline-block;
  background-color: transparent;
  text-align: center;
  text-transform: uppercase;
  color: $white;
  border: 1px solid  $white;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 30px;
  padding-right: 30px;
  @include tr(background-color .3s, border-color .3s, color .3s);
  @include hover {
    background-color: $red;
    border-color: $red; }

  &--sec {
    background-color: $red-accent;
    @include light;
    font-size: $fz-md;
    border-color: $red-accent;
    padding: 4px 10px;
    min-width: 155px;
    @include hover {
      background-color: transparent;
      color: $red-accent; } }
  &--black {
    background-color: $c-text;
    color: $white;
    border-color: $c-text;
    @include hover {
      background-color: transparent;
      color: $white;
      border-color: $white; } }
  &--md {
    min-width: 210px;
    font-size: $fz-md;
    padding: 7px 10px; }
  &--lg {
    min-width: 260px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 9px;
    padding-bottom: 8px;
    font-size: $fz;
    @include sm {
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: $fz-md; } }
  &--full {
    width: 100%; }
  &--arrow {
    position: relative;
    padding-right: 2em;
    @include before {
      display: inline-block;
      @include icon-arrow(10, 10, 1, currentColor, -135);
      position: absolute;
      top: .5em;
      right: 1em;
      @include tr(transform .3s); }
    &.is-active {
      @include before {
        @include icon-arrow(10, 10, 1, currentColor, -45); } } }
  &--red {
    color: $red-accent;
    border-color: $red-accent;
    @include hover {
      background-color: $white;
      border-color: $white; } }
  &--transp {
    background-color: transparent;
    color: $c-text;
    @include hover {
      background-color: $c-text;
      color: $white; } }
  &--transp-red {
    background-color: transparent;
    color: $red;
    @include hover {
      background-color: $red;
      color: $white; } } }


.line-sm {
  height: 45px;
  width: 1px;
  background-color: $white;
  display: inline-block;
  transform: translateY(-100%);
  &--text {
    background-color: $c-text;
    transform: translateY(0); }
  &--red {
    background-color: $red-accent; } }

.btn-link {
  font-size: $fz-sm;
  text-decoration: underline;
  color: $white;
  @include light;
  text-transform: uppercase;
  @include hover {
    text-decoration: none; } }

.price {
  @include semi;
  font-size: 36px;
  color: $red;
  text-transform: uppercase;
  @include md {
    font-size: 32px; }
  @include sm {
    font-size: 28px; }

  &--sm {
    font-size: 24px;
    color: $c-text;
    small {
      font-size: 10px;
      @include light; } }
  &--red {
    color: $red; } }

.text-stroke {
  @include css-lock(100, 260, 576, 1199, font-size);
  text-transform: uppercase;
  display: inline-block;
  line-height: .8;
  @include black;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: $red;
  text-stroke-width: 1px;
  text-stroke-color: $red;

  &--col-light {
    -webkit-text-stroke-color: rgba($white, .2);
    text-stroke-color: rgba($white, .2); }
  &--white {
    -webkit-text-stroke-color: $white;
    text-stroke-color: $white; } }

.section-word {
  @include bold;
  @include css-lock(100, 260, 576, 1199, font-size);
  line-height: .8;
  color: rgba($white, .1);
  text-transform: uppercase; }

.toggle-btn {
  margin-bottom: 15px;
  display: none;
  &--md {
    @include md {
      display: block; } } }

.toggle-block {
  &--md {
    @include md {
      display: none; } } }

.subscribe {
  $this: &;
  text-align: center;
  &__inner {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto; }
  &__title {
    margin-bottom: 15px; }
  &__subttl {
    margin-bottom: 50px; }
  &__form {
    .input {
      margin-bottom: 15px; } }

  &--lg {
    #{$this}__inner {
      max-width: 930px; }
    #{$this}__title {
      margin-bottom: 35px; }
    #{$this}__text {
      margin-bottom: 50px; }
    #{$this}__form {
      .input {
        margin-bottom: 0;
        @include xs {
          margin-bottom: 10px; } } } } }


.counter {
  font-size: 0;
  input, button {
    height: 40px;
    text-align: center;
    border: 1px solid  $gray-light;
    display: inline-block;
    vertical-align: top; }
  input {
    padding: 10px;
    width: 60px;
    font-size: $fz; }
  button {
    background-color: transparent;
    color: $gray-light;
    width: 40px;
    font-size: 26px; } }



// ============ animations ===============

@keyframes scrollDown {
  0% {
    opacity: 0.3; }
  50% {
    opacity: 1; }
  100% {
    opacity: 0.3; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.fadeInBottom {
  animation-name: fadeInBottom; }

.fadeInBottomLg {
  animation-name: fadeInBottomLg; }

.fadeInLeft {
  animation-name: fadeInLeft; }

.fadeIn {
  animation-name: fadeIn; }

@keyframes fadeInBottom {
  0% {
    opacity: 0;
    transform: translate(0, 30px); }
  100% {
    opacity: 1;
    transform: translate(0, 0); } }

@keyframes fadeInBottomLg {
  0% {
    opacity: 0;
    transform: translate(0, 200px); }
  100% {
    opacity: 1;
    transform: translate(0, 0); } }

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate(100px, 0); }
  100% {
    opacity: 1;
    transform: translate(0, 0); } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
