.no-touch {
  .no-scroll {
    .header {
 } } }      // right: 17px

.header {
  $this: &;
  position: fixed;
  background-color: $c-text;
  top: 0;
  left: 0;
  right: 0;
  z-index: 15;
  @include flex(space-between, center);
  padding-right: 30px;
  height: $header-height;
  border-bottom: 1px solid $white-transp;
  @include xl {
    height: $header-height-md; }

  @include lg {
    justify-content: flex-start;
    height: $header-height-mob;
    padding-right: 15px;
    padding-left: 15px; }


  &.is-black {
    background-color: #000; }
  &__logo {
    position: relative;
    width: 250px;
    z-index: 1;
    @include xl {
      width: 180px; }
    @include lg {
      flex-grow: 1; }
    @include xs {
      width: auto; } }
  &__nav {
    flex-grow: 1;
    text-align: center;
    // transform: translate(0, 12px)
    margin-top: 25px;
    &.is-active {
      transform: translate(0, 0);
      top: $header-height;
      @include lg {
        top: $header-height-mob; } }
    @include xl {
      margin-top: 0; }
    @include lg {
      position: absolute;
      margin-top: 0;
      top: 0;
      left: 0;
      background-color: #000;
      width: 100%;
      height: calc(100vh - #{$header-height-mob});
      overflow: auto;
      @include flex(center, center);
      transform: translate(0, -100%);
      @include tr(transform .3s); } }
  &__social {
    // position: absolute
    // top: 8px
    // left: 100%
    // min-width: 105px
    // +xl
    //   top: 20px
    // +lg
    //   position: static
    li {
      white-space: nowrap; } }
  &__lang {
    width: 250px;
    text-align: right;
    position: relative;
    z-index: 1;
    @include flex(flex-start, center);
    @include xl {
 }      // width: 200px
    @include lg {
      width: auto; } }
  &__cart {
    margin-right: 10px;
    padding-right: 10px;
    display: block;
    position: relative;
    font-size: 0;
    @include r(360) {
      display: none; }
    .icon {
      font-size: 22px;
      fill: $white; } }
  &__cart-sup {
    font-size: 10px;
    color: $white;
    text-align: center;
    border-radius: 50%;
    display: inline-block;
    background-color: $red;
    width: 18px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    position: absolute;
    top: -1em;
    right: 0; }
  &__burger {
    display: none;
    margin-left: 30px;
    @include lg {
      display: block; }
    @include xs {
      margin-left: 10px;
      padding-top: 5px; } }
  &__contacts {
    font-size: 14px;
    @include bold;
    color: $white;
    margin-right: 35px;
    @include xs {
      margin-right: 10px; }
    a {
      display: block; } }
  // &__subnav
  //   position: absolute
  //   top: 100%
  //   left: 0
  //   width: 100%
  //   overflow: hidden
  //   // padding-top: 4px
  //   // display: none
  //   max-height: 0
  //   +lg
  //     position: relative
  //     max-height: none
  //     display: none
  //     &.is-active
  //       display: block
  //   +before
  //     width: 100%
  //     height: 4px
  //     background-color: $red
  //     top: 0
  //     left: 0
  //     position: absolute
  //     display: block
  //     transform: translate(-100%, 0)
  //     +tr(transform .3s)
  //     z-index: 1
  //     +lg
  //       display: none
  //       transform: translate(0, 0)
  //   .nav
  //     transform: translate(0, -100%)
  //     +tr(opacity .3s)
  //     +lg
  //       transform: translate(0, 0)
  //   &.is-active
  //     max-height: none
  //     +lg
  //       .nav
  //         animation: none
  //     .nav
  //       animation: slideDown .3s forwards
  //       animation-delay: .3s
  //     +before
  //       transform: translate(0, 0)

  &--transp {
    background-color: transparent;
    .nav {
      a.is-active {
        background-color: rgba($white, .3); } } } }


.lang {
  display: inline-block;
  li {
    @include notlast {
      border-bottom: 1px solid $white; } }
  a {
    text-transform: uppercase;
    @include light;
    color: $white;
    font-size: 14px;
    &.is-active {
      @include semi; } } }

.burger {
  display: block;
  width: 28px;
  height: 28px;
  span:after, span:before {
    content: "";
    position: absolute;
    left: 0;
    top: 9px; }
  span:after {
    top: 18px; }
  span {
    position: relative;
    display: block; }
  span, span:after, span:before {
    width: 100%;
    height: 2px;
    background-color: #fff;
    transition: all 0.3s;
    backface-visibility: hidden;
    border-radius: 2px; }
  &.is-active span {
    background-color: transparent; }

  &.is-active span:before {
    transform: rotate(45deg) translate(-1px, 0px); }

  &.is-active span:after {
    transform: rotate(-45deg) translate(6px, -7px); } }


@keyframes slideDown {
  0% {
    transform: translate(0, -100%);
    opacity: 0; }
  100% {
    transform: translate(0, 0);
    opacity: 1; } }
